import {
  twentyYearBackDate,
  todayAndPrevDate,
  currentDate,
  lastMonth,
  lastYear,
} from "./helperFunctions";

export const Constant = {
  TWENTY_YEAR_BACK_DATE: twentyYearBackDate().twentyYearBackDate,
  TWENTY_YEAR_BACK_DATE_FOR_SCHEMA:
    twentyYearBackDate().twentyYearBackDateForSchema,
  TODAY_DATE: todayAndPrevDate().todayDate,
  PREV_DATE: todayAndPrevDate().prevDate,
  CURRENT_DATE: currentDate().currentDateForSchema,
  LASTMONTH: lastMonth(),
  LASTYEAR: lastYear(),
  RESPONSE_STATUS: {
    SUCCESS: 0,
    ERROR: 1,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
  },
  EMPTYSLOT: {
    HOLIDAY: "NO HOLIDAY",
    EMPLOYEE: "NO EMPLOYEE",
    LEAVES: "NO LEAVES",
    SALARY: "NO SALARY",
    EVENTS: "NO EVENTS",
  },
  LEAVESTATUS: {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2,
  },
  USERS: {
    ACTIVE: 1,
  },
  LEAVETYPE: {
    HALF: "0",
    FULL: "1",
  },
  NO_OF_DAYS: 30,
  WORKSTATUS: {
    ON: 1,
    OFF: 0,
  },
  ROLES: {
    ADMIN: "Admin",
    USER: "User",
  },
};

export const employeeRoles = [
  { id: 1, name: "Trainee" },
  { id: 2, name: "Developer" },
  { id: 3, name: "Sr. Developer" },
  { id: 4, name: "Team Lead" },
  { id: 5, name: "Project Manager" },
  { id: 6, name: "HR" },
  { id: 7, name: "Accountant" },
];

export const companyNames = [
  { id: 1, name: "3Brain Technolabs Pvt. Ltd." },
  // { id: 2, name: "3Brain Technology Services" },
];
export const Roles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "User" },
];
